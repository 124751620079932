<template>
  <v-row class="row--35" align="center">
    <v-col lg="6" md="6" cols="12" align="center">
        <div class="about-inner inner pt--100 pt_sm--40 pt_md--40">
            <div class="section-title">
                <h2 class="heading-title">the ceremony</h2>
                <p class="description">
                    three thirty arrival for a four o'clock ceremony <br />
                    rocklea farm <br />
                    under the tree <br />
                    <a href="https://goo.gl/maps/EssZPsQKMfFsCWK46">
                        159 friend in hand road, stonehaven vic
                    </a> <br />
                </p>
                <p class="description italic">cocktail attire</p>
            </div>
        </div>
    </v-col>
    <v-col lg="6" md="6" cols="12" align="center">
      <div class="about-inner inner pt--100 pt_sm--40 pt_md--40">
          <div class="section-title">
              <h2 class="heading-title">the reception</h2>
              <p class="description">
                  kicks off straight after we're wed! <br />
                  rocklea farm <br />
                  in the courtyard <br />
                  drinks, dinner &amp; dancing
              </p>
              <p class="description italic">bring your dancing shoes</p>
          </div>
        <!-- End .section-title -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import TabTwo from "../../components/tabs/TabTwo";
  export default {
    components: { TabTwo },
    data() {
      return {};
    },
  };
</script>
